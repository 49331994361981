import React from 'react';
import Hero from '../sections/landing7/Hero';
import Fact from '../sections/landing7/Fact';
import Content from '../sections/landing7/Content';
import CaseStudies from '../sections/landing7/CaseStudies';
import Reviews from '../sections/landing7/Reviews';
import TechStack from '../sections/landing7/TechStack';
import { Helmet } from 'react-helmet';
import PageWrapper from '../components/PageWrapper';

const IndexPage = () => {
    return (
        <>
            <PageWrapper headerDark footerDark>
                <Helmet>
                    <title data-react-helmet="true">EMPG LABS</title>
                    <meta
                        name="description"
                        content="EMPG LABS is the tech arm of the group that is building scalable solutions and handles millions of users across the globe. We have a tech team of 500+ members across the globe."
                    />
                    <meta property="og:url" content="https://www.empglabs.com" />
                    <meta property="og:title" content="EMPG LABS" />
                    <meta
                        property="og:description"
                        content="EMPG LABS is the tech arm of the group that is building scalable solutions and handles millions of users across the globe. We have a tech team of 500+ members across the globe."
                    />
                    <meta property="og:image" content="" />
                    <link rel="canonical" href="https://www.empglabs.com" />
                </Helmet>
                <Hero />
                <Fact />
                <Content />
                <CaseStudies />
                <TechStack />
                <Reviews />
            </PageWrapper>
        </>
    );
};
export default IndexPage;
