import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { Button, Section, Box, Text } from '../../components/Core';
import HeroImage from '../../assets/image/jpeg/hero-bg4.jpg';
import { device } from '../../utils';

const SectionStyled = styled(Section)`
    background-image: url(${HeroImage});
    background-size: cover;
    display: flex;
    align-items: center;
    height: 320px;
    @media ${device.md} {
        height: 630px;
    }
    @media screen and (min-width: 2561px) {
        height: 690px;
    }
    .brand-logo {
        img {
            max-width: 480px;
            width: 90%;
        }
    }
`;
const Hero = ({ isDark = false }) => {
    return (
        <>
            {/* <!-- Hero Area --> */}
            <SectionStyled>
                <Container
                    className="position-relative"
                    css={`
                        z-index: 10;
                    `}
                >
                    <Row>
                        <Col>
                            <Box>
                                <Text
                                    color="light"
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-once="true"
                                    data-aos-delay="700"
                                    style={{
                                        fontSize: '30px',
                                        lineHeight: '1.2',
                                        textShadow: '0 0.5rem 0.5rem rgb(0 0 0 / 15%);',
                                    }}
                                >
                                    Want to work with us?
                                </Text>
                                {/* <Text
                                    mt="24px"
                                    color="light"
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-once="true"
                                    data-aos-delay="1000"
                                    style={{
                                        fontSize: '18px',
                                        textShadow: '0 0.5rem 0.5rem rgb(0 0 0 / 15%);',
                                    }}
                                >
                                    Build. Scale. Learn. Repeat.
                                </Text> */}
                                {/* <div
                                    className="brand-logo my-4"
                                    variant="hero"
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-once="true"
                                    data-aos-delay="1000"
                                >
                                    <Logo white={true} />
                                </div> */}
                                <Box
                                    mt="18px"
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-once="true"
                                    data-aos-delay="1300"
                                >
                                    <a
                                        href="https://empglabs.breezy.hr/"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <Button
                                            className=""
                                            style={{
                                                minWidth: '140px',
                                                fontSize: '16px',
                                                height: '48px',
                                            }}
                                        >
                                            Apply Now
                                        </Button>
                                    </a>
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </SectionStyled>
        </>
    );
};

export default Hero;
