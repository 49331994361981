import React from 'react';
import { Container } from 'react-bootstrap';

import { Title, Section, Box, Text } from '../../components/Core';

import Slider from 'react-slick';

import imgR1 from '../../assets/image/jpeg/testimonial-user-1.jpg';
import imgR2 from '../../assets/image/jpeg/testimonial-user-2.jpg';
import imgR3 from '../../assets/image/jpeg/testimonial-user-3.jpg';
import imgR4 from '../../assets/image/jpeg/testimonial-user-4.jpg';
import imgR5 from '../../assets/image/jpeg/testimonial-user-5.jpg';
import imgR6 from '../../assets/image/jpeg/testimonial-user-6.jpg';
import imgR7 from '../../assets/image/jpeg/testimonial-user-7.jpg';
import imgR8 from '../../assets/image/jpeg/testimonial-user-8.jpg';

const ContentCard = ({
    className,
    //imageBrand = imgB1,
    image,
    name,
    company,
    team,
    children,
    ...rest
}) => (
    <Box
        borderColor="border"
        borderRadius={10}
        className={`${className}`}
        {...rest}
        aos="fade-left"
        data-aos-delay="500"
        data-aos-duration="1000"
        data-aos-once="true"
    >
        {/* <Box className="text-center">
      <img src={imageBrand} alt="" className="img-fluid" />
    </Box> */}
        <Box mb="5">
            <Box className="text-center">
                <img
                    src={image}
                    alt=""
                    className="img-fluid d-inline-block"
                    css={`
                        border-radius: 500px;
                    `}
                />
            </Box>
            <Box className="flex-grow-1 text-center" mt={3}>
                <Title color="light" variant="card" mb={1}>
                    {name}
                </Title>
                <Text variant="small" color="lightShade" opacity={0.5}>
                    {company}
                </Text>
                <Text
                    variant="small"
                    color="lightShade"
                    opacity={0.5}
                    style={{ lineHeight: '14px' }}
                >
                    {team}
                </Text>
            </Box>
        </Box>
        <Text color="lightShade" mb={3} className="text-center mb-0">
            {children}
        </Text>
    </Box>
);

const Reviews = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    };
    return (
        <>
            <Section bg="dark">
                <Container>
                    <Slider {...settings}>
                        <ContentCard
                            name="Hasnain Azam"
                            company="Senior Software Engineer"
                            team="Team Autos"
                            image={imgR8}
                            //imageBrand={imgB1}
                        >
                            The exposure and learning experience EMPG LABS has offered, helped me
                            get to greater heights in my professional career.
                        </ContentCard>

                        <ContentCard
                            name="Zulqarnain Sabir"
                            company="Senior Software Engineer"
                            team="Team Kevlar"
                            image={imgR5}
                            //imageBrand={imgB1}
                        >
                            EMPG Labs is a great place to work. Working with great minds, in a
                            friendly environment has helped me to grow personally and
                            professionally.
                        </ContentCard>

                        <ContentCard
                            name="Safwan Ahmed"
                            company="Senior Software Engineer"
                            team="Team MobileApps"
                            image={imgR4}
                            //imageBrand={imgB1}
                        >
                            Supportive management, ample learning opportunities and meaningful
                            rewards and recognition makes it stand apart from others in the
                            marketplace.
                        </ContentCard>

                        <ContentCard
                            name="Sumayya Shahzad"
                            company="Principal Software Test Engineer"
                            team="Team CRM"
                            image={imgR7}
                        >
                            I can proudly say this has been a great experience so far. EMPG Labs is
                            fundamentally a strong company with a lot of opportunities to learn.
                        </ContentCard>

                        <ContentCard
                            name="Agha Qamar"
                            company="Associate Product Manager"
                            team="Team OLX"
                            image={imgR3}
                        >
                            I’ve been working as a Product Manager at EMPG LABS for over a year now,
                            and have witnessed the best working culture, reflecting perfectionism
                            and ultimate work ethics.
                        </ContentCard>
                        <ContentCard
                            name="Sidra Kanwal"
                            company="Project Manager"
                            team="Team Product"
                            image={imgR6}
                        >
                            EMPG LABS gave me the chance to work in a diverse environment where I am
                            able to contribute my skills and grow even more.
                        </ContentCard>

                        <ContentCard
                            name="Mahwish Naqvi"
                            company="Associate Project Manager"
                            team="Team Bayut"
                            image={imgR1}
                            //imageBrand={imgB1}
                        >
                            The progressive culture & strong camaraderie within the team at EMPG
                            LABS inspires you to challenge your limitations and go beyond the
                            frontiers of your intellect.
                        </ContentCard>

                        <ContentCard
                            name="Mahham Baber"
                            company="Project Manager"
                            team="Team PropForce"
                            image={imgR2}
                        >
                            From office location, perks, growth ladder, learning opportunities and
                            overall exposure; makes all the reasons for me to stick around for 4
                            years and counting.
                        </ContentCard>
                    </Slider>
                </Container>
            </Section>
        </>
    );
};

export default Reviews;
