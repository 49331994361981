import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import Laravel from '../../assets/image/svg/laravel.svg';
import ROR from '../../assets/image/svg/ror-logo.svg';
import Angular from '../../assets/image/svg/angular.svg';
import NextJs from '../../assets/image/svg/next-js.svg';
import NodeJS from '../../assets/image/svg/nodejs.svg';
import ReactJs from '../../assets/image/svg/React.svg';
import Swift from '../../assets/image/svg/swift.svg';
import Gatsby from '../../assets/image/svg/gatsby.svg';
import Python from '../../assets/image/svg/python.svg';
import Django from '../../assets/image/svg/django.svg';
import Kotlin from '../../assets/image/svg/kotlin.svg';

import { Title, Section, Box } from '../../components/Core';
import { device } from '../../utils';
const SectionStyled = styled(Section)`
    border-bottom: 1px solid #2d2d30;
`;

const ContentWidget = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    transition: 0.4s;

    @media ${device.xl} {
        width: 240px;
        min-width: 240px;
    }
    @media ${device.xs} {
        img {
            max-width: 90%;
        }
    }
    i {
        font-size: 30px;
        color: #fff;
        position: absolute;
        right: 0;
        margin-right: 30px;
        opacity: 0;
        transition: 0.4s;
    }

    &:hover {
        box-shadow: 0 52px 54px rgba(25, 25, 27, 0.3);
    }
    &:hover i {
        transform: translateX(10px);
        opacity: 1;
    }
`;

const ContentCard = ({ children = '', url }) => {
    return (
        <ContentWidget>
            <div className={`d-flex align-items-center w-100 px-4`}>
                <Title
                    className="d-flex justify-content-between w-100"
                    color="light"
                    variant="card"
                    mb={0}
                >
                    {children}
                </Title>
            </div>
            {/* 
      <i className="fas fa-chevron-circle-right"></i> */}
        </ContentWidget>
    );
};

const TechStack = () => {
    return (
        <>
            {/* <!-- Content section --> */}
            <SectionStyled bg="dark">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="10">
                            <div className="text-center mb-5">
                                <Title color="light">Technologies</Title>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col
                            lg="3"
                            xs="6"
                            className="mb-5 d-flex"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="400"
                        >
                            <ContentCard>
                                <img
                                    src={ReactJs}
                                    alt="ReactJs"
                                    className="mx-auto"
                                    height="70px"
                                    style={{ filter: 'brightness(0) invert(1)' }}
                                />
                            </ContentCard>
                        </Col>
                        <Col
                            lg="3"
                            xs="6"
                            className="mb-5 d-flex"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="600"
                        >
                            <ContentCard url="https://bayut.com/">
                                <img src={NextJs} alt="NextJs" className="mx-auto" height="70px" />
                            </ContentCard>
                        </Col>
                        <Col
                            lg="3"
                            xs="6"
                            className="mb-5 d-flex"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="800"
                        >
                            <ContentCard>
                                <img
                                    src={NodeJS}
                                    alt="NodeJS"
                                    className="mx-auto"
                                    height="70px"
                                    style={{ filter: 'brightness(0) invert(1)' }}
                                />
                            </ContentCard>
                        </Col>
                        <Col
                            lg="3"
                            xs="6"
                            className="mb-5 d-flex"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="1000"
                        >
                            <ContentCard>
                                <img
                                    src={Python}
                                    alt="Python"
                                    className="mx-auto"
                                    height="50px"
                                    style={{ filter: 'brightness(0) invert(1)' }}
                                />
                            </ContentCard>
                        </Col>
                        <Col
                            lg="3"
                            xs="6"
                            className="mb-5 d-flex"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="1200"
                        >
                            <ContentCard>
                                <img
                                    src={Django}
                                    alt="Django"
                                    className="mx-auto"
                                    height="70px"
                                    style={{ filter: 'brightness(0) invert(1)', maxWidth: '70%' }}
                                />
                            </ContentCard>
                        </Col>
                        <Col
                            lg="3"
                            xs="6"
                            className="mb-5 d-flex"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="1400"
                        >
                            <ContentCard>
                                <img
                                    src={Swift}
                                    alt="Swift"
                                    className="mx-auto"
                                    height="70px"
                                    style={{ maxWidth: '80%' }}
                                />
                            </ContentCard>
                        </Col>
                        <Col
                            lg="3"
                            xs="6"
                            className="mb-5 d-flex"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="1600"
                        >
                            <ContentCard>
                                <img
                                    src={Kotlin}
                                    alt="Kotlin"
                                    className="mx-auto"
                                    style={{ filter: 'brightness(0) invert(1)', maxWidth: '80%' }}
                                    height="70px"
                                />
                            </ContentCard>
                        </Col>
                        <Col
                            lg="3"
                            xs="6"
                            className="mb-5 d-flex"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="1800"
                        >
                            <ContentCard>
                                <img
                                    src={Laravel}
                                    alt="Laravel"
                                    className="mx-auto"
                                    height="70px"
                                    style={{ filter: 'brightness(0) invert(1)' }}
                                />
                            </ContentCard>
                        </Col>
                        <Col
                            lg="3"
                            xs="6"
                            className="d-flex"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="1800"
                        >
                            <ContentCard>
                                <img
                                    src={ROR}
                                    alt="ROR"
                                    className="mx-auto"
                                    height="70px"
                                    style={{ filter: 'brightness(0) invert(1)', maxWidth: '80%' }}
                                />
                            </ContentCard>
                        </Col>
                        <Col
                            lg="3"
                            xs="6"
                            className="d-flex"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="1800"
                        >
                            <ContentCard>
                                <img
                                    src={Angular}
                                    alt="Angular"
                                    className="mx-auto"
                                    height="70px"
                                />
                            </ContentCard>
                        </Col>
                        <Col
                            lg="3"
                            xs="6"
                            className="d-flex"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="1800"
                        >
                            <ContentCard>
                                <img src={Gatsby} alt="Gatsby" className="mx-auto" height="70px" />
                            </ContentCard>
                        </Col>
                    </Row>
                </Container>
            </SectionStyled>
        </>
    );
};

export default TechStack;
