import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Title, Section, Box, Text } from '../../components/Core';
import { device } from '../../utils';

const SectionStyled = styled(Section)`
    border-bottom: 1px solid #2d2d30;
    padding-bottom: 30px;
    padding-top: 30px;
    @media ${device.md} {
        padding-bottom: 70px;
        padding-top: 70px;
    }
`;

const Fact = () => (
    <>
        {/* <!-- Fact section 1 --> */}
        <SectionStyled bg="dark">
            <Container>
                <Row className="justify-content-between">
                    <Col md="auto" className="mb-4 mb-md-0">
                        <Box className="text-center">
                            <Title color="light" mb="0!important">
                                16
                            </Title>
                            <Text color="lightShade">Countries</Text>
                        </Box>
                    </Col>
                    <Col md="auto" className="mb-4 mb-lg-0">
                        <Box className="text-center">
                            <Title color="light" mb="0!important">
                                6,750
                            </Title>
                            <Text color="lightShade" className="text-nowrap">
                                Total Group Employees
                            </Text>
                        </Box>
                    </Col>
                    <Col md="auto" className="mb-4 mb-md-0">
                        <Box className="text-center">
                            <Title color="light" mb="0!important">
                                217,000,000
                            </Title>
                            <Text color="lightShade">Total Group Monthly Traffic</Text>
                        </Box>
                    </Col>
                    <Col md="auto">
                        <Box className="text-center">
                            <Title color="light" mb="0!important">
                                10
                            </Title>
                            <Text color="lightShade">Brands</Text>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </SectionStyled>
    </>
);

export default Fact;
