import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import Logo from '../../components/Logo';
import { Title, Box } from '../../components/Core';
import { device } from '../../utils';
import Slider from 'react-slick';
import imgCase1 from '../../assets/image/jpeg/life-1.jpg';
import imgCase2 from '../../assets/image/jpeg/life-2.jpg';
import imgCase3 from '../../assets/image/jpeg/life-3.jpg';
import imgCase4 from '../../assets/image/jpeg/life-4.jpg';
import imgCase5 from '../../assets/image/jpeg/life-5.jpg';
import imgCase6 from '../../assets/image/jpeg/life-6.jpg';
import imgCase7 from '../../assets/image/jpeg/life-7.jpg';

const SectionStyled = styled(Box)`
    border-bottom: 1px solid #2d2d30;
    padding-bottom: 64px;
    padding-top: 64px;
    @media ${device.md} {
        padding-bottom: 120px;
        padding-top: 120px;
    }
    .brand-logo {
        display: inline-block;
        margin-left: 14px;
        position: relative;
        top: -5px;
        img {
            width: 300px;
        }
    }
`;

const CaseCardStyled = styled(Box)`
    width: 100%;
    transition: 0.5s;

    .img-container {
        position: relative;
        z-index: 1;
        img {
            border-radius: 8px;
            max-width: 100%;
            cursor: pointer;
        }
    }

    &:hover {
        transform: scale(1.05);
    }
    &:hover i {
        transform: translateX(10px);
        opacity: 1;
    }
`;

const CaseCard = ({
    isDark = true,
    bg = 'secondary',
    img,
    meta = '',
    title = '',
    children = '',
}) => {
    return (
        <CaseCardStyled>
            <div className="img-container">
                <img src={img} alt="" />
            </div>
        </CaseCardStyled>
    );
};

const CaseStudies = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    };
    return (
        <>
            {/* <!-- Content section --> */}
            <SectionStyled bg="dark">
                <Container>
                    <div className="text-center mb-5">
                        <Title color="light">
                            Life at
                            <div className="brand-logo">
                                <Logo white={true} />
                            </div>
                        </Title>
                    </div>
                    <Slider {...settings} className="life-at-empg">
                        <div
                            className="image-box"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="500"
                        >
                            <CaseCard
                                bg="light"
                                isDark={false}
                                img={imgCase4}
                                meta={`Content Writing, Digital Marketing`}
                                title="How we’ve helped the most interesting startup."
                            ></CaseCard>
                        </div>
                        <div
                            className="image-box"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="500"
                        >
                            <CaseCard
                                bg="light"
                                isDark={false}
                                img={imgCase6}
                                meta={`Content Writing, Digital Marketing`}
                                title="How we’ve helped the most interesting startup."
                            ></CaseCard>
                        </div>

                        <div
                            className="image-box"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                        >
                            <CaseCard
                                img={imgCase3}
                                meta={`Website Development, Search Engine Optimization`}
                                title="Growing Organic Customers for Brag Business."
                            ></CaseCard>
                        </div>
                        <div
                            className="image-box"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="500"
                        >
                            <CaseCard
                                bg="light"
                                isDark={false}
                                img={imgCase7}
                                meta={`Content Writing, Digital Marketing`}
                                title="How we’ve helped the most interesting startup."
                            ></CaseCard>
                        </div>
                        <div
                            className="image-box"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="500"
                        >
                            <CaseCard
                                bg="light"
                                isDark={false}
                                img={imgCase2}
                                meta={`Content Writing, Digital Marketing`}
                                title="How we’ve helped the most interesting startup."
                            ></CaseCard>
                        </div>
                        <div
                            className="image-box"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                        >
                            <CaseCard
                                img={imgCase1}
                                meta={`Website Development, Search Engine Optimization`}
                                title="Growing Organic Customers for Brag Business."
                            ></CaseCard>
                        </div>
                        <div
                            className="image-box"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="500"
                        >
                            <CaseCard
                                bg="light"
                                isDark={false}
                                img={imgCase5}
                                meta={`Content Writing, Digital Marketing`}
                                title="How we’ve helped the most interesting startup."
                            ></CaseCard>
                        </div>
                    </Slider>
                </Container>
            </SectionStyled>
        </>
    );
};

export default CaseStudies;
